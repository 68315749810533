<template>
	<div class="masters">
		<SubHeader title="ODO Masters" subtitle="ODOマスターズ" />

		<div class="container">
			<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link" href="/masters/2024/"> 2024（2023年度） </a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/masters/2023/"> 2023（2022年度） </a>
				</li>
				<li class="nav-item">
					<a class="nav-link active" href="javascript:void(0);">
						2020（2019年度）
					</a>
				</li>
			</ul>

			<div class="row justify-content-center">
				<div class="col-lg-12">
					<h2>ODOマスターズ2020（2019年度）</h2>
					<h5>開催：2021年4月11日（日）、会場：Balabushka</h5>
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-3 text-center offset-1">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/masters/2020/y_isowa.jpg"
							class="img-fluid"
							@click="openGallery(1)"
						/>
						<span class="name">磯和 勇希</span>
					</p>
				</div>
				<div class="col-4 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/masters/2020/d_yamazaki.jpg"
							class="img-fluid"
							@click="openGallery(0)"
						/>
						<span class="name">山﨑 大地</span>
					</p>
				</div>
				<div class="col-3 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/masters/2020/r_nagayo.jpg"
							class="img-fluid"
							@click="openGallery(2)"
						/>
						<span class="name">長代 龍次朗</span>
					</p>
				</div>
			</div>

			<h4>4th &amp; BEST8</h4>
			<div class="row mb-4">
				<div class="col-lg-2 col-4 text-center offset-lg-1">
					<p>
						<span class="rank">4th</span>
						<img
							src="/images/masters/2020/y_nishimoto.jpg"
							class="img-fluid"
							@click="openGallery(3)"
						/>
						<span class="name">西本 陽一</span>
					</p>
				</div>
				<div class="col-lg-2 col-4 text-center">
					<p>
						<span class="rank">BEST8</span>
						<img
							src="/images/masters/2020/t_furukawa.jpg"
							class="img-fluid"
							@click="openGallery(4)"
						/>
						<span class="name">古川 隆男</span>
					</p>
				</div>
				<div class="col-lg-2 col-4 text-center">
					<p>
						<span class="rank">BEST8</span>
						<img
							src="/images/masters/2020/t_takayama.jpg"
							class="img-fluid"
							@click="openGallery(5)"
						/>
						<span class="name">高山 得溶</span>
					</p>
				</div>
				<div class="col-lg-2 col-4 text-center">
					<p>
						<span class="rank">BEST8</span>
						<img
							src="/images/masters/2020/n_ishii.jpg"
							class="img-fluid"
							@click="openGallery(6)"
						/>
						<span class="name">石井 庸介</span>
					</p>
				</div>
				<div class="col-lg-2 col-4 text-center">
					<p>
						<span class="rank">BEST8</span>
						<img
							src="/images/masters/2020/h_sotoosa.jpg"
							class="img-fluid"
							@click="openGallery(7)"
						/>
						<span class="name">外筬 久人</span>
					</p>
				</div>
			</div>

			<h4>BEST16</h4>
			<div class="row">
				<div class="col-lg-2 col-3 text-center offset-lg-2">
					<p>
						<span class="rank">BEST16</span>
						<img
							src="/images/masters/2020/m_ueda.jpg"
							class="img-fluid"
							@click="openGallery(8)"
						/>
						<span class="name">上田 昌志</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST16</span>
						<img
							src="/images/masters/2020/s_hayashi.jpg"
							class="img-fluid"
							@click="openGallery(9)"
						/>
						<span class="name">林 昇一</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST16</span>
						<img
							src="/images/masters/2020/h_tanida.jpg"
							class="img-fluid"
							@click="openGallery(10)"
						/>
						<span class="name">谷田 尚嗣</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST16</span>
						<img
							src="/images/masters/2020/r_takahashi.jpg"
							class="img-fluid"
							@click="openGallery(11)"
						/>
						<span class="name">髙橋 綾也</span>
					</p>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-lg-2 col-3 text-center offset-lg-2">
					<p>
						<span class="rank">BEST16</span>
						<img
							src="/images/masters/2020/k_odachi.jpg"
							class="img-fluid"
							@click="openGallery(12)"
						/>
						<span class="name">大達 浩司</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST16</span>
						<img
							src="/images/masters/2020/t_kurokawa.jpg"
							class="img-fluid"
							@click="openGallery(13)"
						/>
						<span class="name">黒川 智成</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST16</span>
						<img
							src="/images/masters/2020/y_murai.jpg"
							class="img-fluid"
							@click="openGallery(14)"
						/>
						<span class="name">村井 陽介</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST16</span>
						<img
							src="/images/masters/2020/s_nakanishi.jpg"
							class="img-fluid"
							@click="openGallery(15)"
						/>
						<span class="name">中西 信</span>
					</p>
				</div>
			</div>

			<h4>BEST32</h4>
			<div class="row">
				<div class="col-lg-2 col-3 text-center offset-lg-1">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/r_oda.jpg"
							class="img-fluid"
							@click="openGallery(16)"
						/>
						<span class="name">小田 亮二</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/s_tahara.jpg"
							class="img-fluid"
							@click="openGallery(17)"
						/>
						<span class="name">田原 駿也</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/k_yabuta.jpg"
							class="img-fluid"
							@click="openGallery(18)"
						/>
						<span class="name">薮田 和哉</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/y_sasaki.jpg"
							class="img-fluid"
							@click="openGallery(19)"
						/>
						<span class="name">佐々木 義高</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/k_tatara.jpg"
							class="img-fluid"
							@click="openGallery(20)"
						/>
						<span class="name">多田良 宏栄</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center offset-lg-1">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/k_kimura.jpg"
							class="img-fluid"
							@click="openGallery(21)"
						/>
						<span class="name">木村 圭吾</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/t_kogama.jpg"
							class="img-fluid"
							@click="openGallery(22)"
						/>
						<span class="name">鈳 孝子</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/m_nakamura.jpg"
							class="img-fluid"
							@click="openGallery(23)"
						/>
						<span class="name">中村 真詞</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/k_nakanishi.jpg"
							class="img-fluid"
							@click="openGallery(24)"
						/>
						<span class="name">中西 香菜子</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<span class="rank">BEST32</span>
						<img
							src="/images/masters/2020/y_sunakawa.jpg"
							class="img-fluid"
							@click="openGallery(25)"
						/>
						<span class="name">砂川 祥輝</span>
					</p>
				</div>
			</div>

			<LightBox
				ref="lightbox"
				:media="media"
				:show-light-box="false"
				:show-caption="true"
			></LightBox>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";
//import VueLazyLoad from "vue-lazyload";
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

export default {
	name: "masters",
	components: {
		SubHeader,
		LightBox,
	},
	data() {
		return {
			media: [
				{
					thumb: "/images/masters/2020/d_yamazaki.jpg",
					src: "/images/masters/2020/d_yamazaki.jpg",
					caption: "山﨑 大地",
				},
				{
					thumb: "/images/masters/2020/y_isowa.jpg",
					src: "/images/masters/2020/y_isowa.jpg",
					caption: "磯和 勇希",
				},
				{
					thumb: "/images/masters/2020/r_nagayo.jpg",
					src: "/images/masters/2020/r_nagayo.jpg",
					caption: "長代 龍次朗",
				},

				{
					thumb: "/images/masters/2020/y_nishimoto.jpg",
					src: "/images/masters/2020/y_nishimoto.jpg",
					caption: "西本 陽一",
				},
				{
					thumb: "/images/masters/2020/t_furukawa.jpg",
					src: "/images/masters/2020/t_furukawa.jpg",
					caption: "古川 隆男",
				},
				{
					thumb: "/images/masters/2020/t_takayama.jpg",
					src: "/images/masters/2020/t_takayama.jpg",
					caption: "高山 得溶",
				},
				{
					thumb: "/images/masters/2020/n_ishii.jpg",
					src: "/images/masters/2020/n_ishii.jpg",
					caption: "石井 庸介",
				},
				{
					thumb: "/images/masters/2020/h_sotoosa.jpg",
					src: "/images/masters/2020/h_sotoosa.jpg",
					caption: "外筬 久人",
				},

				{
					thumb: "/images/masters/2020/m_ueda.jpg",
					src: "/images/masters/2020/m_ueda.jpg",
					caption: "上田 昌志",
				},
				{
					thumb: "/images/masters/2020/s_hayashi.jpg",
					src: "/images/masters/2020/s_hayashi.jpg",
					caption: "林 昇一",
				},
				{
					thumb: "/images/masters/2020/h_tanida.jpg",
					src: "/images/masters/2020/h_tanida.jpg",
					caption: "谷田 尚嗣",
				},
				{
					thumb: "/images/masters/2020/r_takahashi.jpg",
					src: "/images/masters/2020/r_takahashi.jpg",
					caption: "髙橋 綾也",
				},
				{
					thumb: "/images/masters/2020/k_odachi.jpg",
					src: "/images/masters/2020/k_odachi.jpg",
					caption: "大達 浩司",
				},
				{
					thumb: "/images/masters/2020/t_kurokawa.jpg",
					src: "/images/masters/2020/t_kurokawa.jpg",
					caption: "黒川 智成",
				},
				{
					thumb: "/images/masters/2020/y_murai.jpg",
					src: "/images/masters/2020/y_murai.jpg",
					caption: "村井 陽介",
				},
				{
					thumb: "/images/masters/2020/s_nakanishi.jpg",
					src: "/images/masters/2020/s_nakanishi.jpg",
					caption: "中西 信",
				},

				{
					thumb: "/images/masters/2020/r_oda.jpg",
					src: "/images/masters/2020/r_oda.jpg",
					caption: "小田 亮二",
				},
				{
					thumb: "/images/masters/2020/s_tahara.jpg",
					src: "/images/masters/2020/s_tahara.jpg",
					caption: "田原 駿也",
				},
				{
					thumb: "/images/masters/2020/k_yabuta.jpg",
					src: "/images/masters/2020/k_yabuta.jpg",
					caption: "薮田 和哉",
				},
				{
					thumb: "/images/masters/2020/y_sasaki.jpg",
					src: "/images/masters/2020/y_sasaki.jpg",
					caption: "佐々木 義高",
				},
				{
					thumb: "/images/masters/2020/k_tatara.jpg",
					src: "/images/masters/2020/k_tatara.jpg",
					caption: "多田良 宏栄",
				},
				{
					thumb: "/images/masters/2020/k_kimura.jpg",
					src: "/images/masters/2020/k_kimura.jpg",
					caption: "木村 圭吾",
				},
				{
					thumb: "/images/masters/2020/t_kogama.jpg",
					src: "/images/masters/2020/t_kogama.jpg",
					caption: "鈳 孝子",
				},
				{
					thumb: "/images/masters/2020/m_nakamura.jpg",
					src: "/images/masters/2020/m_nakamura.jpg",
					caption: "中村 真詞",
				},
				{
					thumb: "/images/masters/2020/k_nakanishi.jpg",
					src: "/images/masters/2020/k_nakanishi.jpg",
					caption: "中西 香菜子",
				},
				{
					thumb: "/images/masters/2020/y_sunakawa.jpg",
					src: "/images/masters/2020/y_sunakawa.jpg",
					caption: "砂川 祥輝",
				},
				{
					thumb: "/images/masters/2020/tournament.jpg",
					src: "/images/masters/2020/tournament.jpg",
					caption: "決勝トーナメント表",
				},
			],
		};
	},
	methods: {
		openGallery(index) {
			this.$refs.lightbox.showImage(index);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
h4 {
	border-bottom: solid 3px #333;
	padding-bottom: 5px;
	margin-bottom: 2rem;
	position: relative;
	&:before {
		content: "";
		width: 3rem;
		height: 3px;
		background-color: $primary-color;
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
	}
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.rank,
.rank_winner,
.rank_2nd,
.rank_3rd,
.name {
	color: #333;
}
.rank_winner {
	font-size: 2rem;
	i {
		color: #dbb400;
	}
}
.rank_2nd {
	font-size: 1.4rem;
	i {
		color: #9fa0a0;
	}
}
.rank_3rd {
	font-size: 1.4rem;
	i {
		color: #c47022;
	}
}
i {
	margin-right: 0.6rem;
}
</style>
